/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useRef } from 'react'
import cx from 'classnames'
import hljs from 'highlight.js'
// Import specific languages

import css from 'highlight.js/lib/languages/css'
import html from 'highlight.js/lib/languages/xml'
import 'highlight.js/styles/atom-one-dark.css' // or any other theme you prefer

import { ATTRIBUTES } from './attributes'

// Register the languages

hljs.registerLanguage('css', css)
hljs.registerLanguage('html', html)

try {
  const cssLang = hljs.getLanguage('css')
  if (cssLang && cssLang.contains) {
    const attr = cssLang.contains.find(({ className }) => className === 'attribute')
    if (attr) {
      attr.begin = '\\b(' + ATTRIBUTES.join('|') + ')\\b'
    }
  }
} catch (error) {
  console.error('Error configuring CSS attributes:', error)
}

const CodeHighlight = React.forwardRef(
  (
    {
      usingForGameEditor,
      children,
      className,
      language,
      style,
      onClick,
      codeRef,
      codeClassName,
      codeStyle,
    }: {
      usingForGameEditor?: boolean
      children: any
      className?: string
      language: string
      style?: any
      onClick?: () => void
      codeRef?: React.Ref<HTMLElement>
      codeClassName?: string
      codeStyle?: any
    },
    ref: React.Ref<HTMLPreElement>
  ) => {
    const codeElement = useRef<HTMLElement>(null)

    const refreshHighlight = () => {
      if (codeElement.current) {
        hljs.highlightElement(codeElement.current)
      }
    }

    useEffect(() => {
      refreshHighlight()
    }, [children])

    return (
      <pre ref={ref} className={className} onClick={onClick} style={style}>
        <code
          ref={(el: any) => {
            if (el) {
              codeElement.current = el
              if (codeRef && 'current' in codeRef) {
                codeRef.current = el
              }
            }
          }}
          className={cx(language, usingForGameEditor && 'usingForGameEditor', codeClassName)}
          style={codeStyle}
        >
          {children}
        </code>
      </pre>
    )
  }
)

CodeHighlight.displayName = 'CodeHighlight'

export default CodeHighlight
