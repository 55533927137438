import { mutate } from 'swr'

import { GRID_ATTACK_GAME_TYPE, USER_GAME_STATS } from 'lib/constants'
import { useUser } from 'lib/hooks'
import { firebaseClient } from 'lib/firebaseClient'

import { User } from 'components/AuthProvider'

import Level1 from './levels/level1'
import Level2 from './levels/level2'
import Level3 from './levels/level3'
import Level4 from './levels/level4'
import Level5 from './levels/level5'
import Level6 from './levels/level6'
import Level7 from './levels/level7'
import Level8 from './levels/level8'
import Level9 from './levels/level9'
import Level10 from './levels/level10'
import Level11 from './levels/level11'
import Level12 from './levels/level12'
import Level13 from './levels/level13'
import Level14 from './levels/level14'
import Level15 from './levels/level15'
import Level16 from './levels/level16'
import Level17 from './levels/level17'
import Level18 from './levels/level18'
import Level19 from './levels/level19'
import Level20 from './levels/level20'
import Level21 from './levels/level21'
import Level22 from './levels/level22'
import Level23 from './levels/level23'
import Level24 from './levels/level24'
import Level25 from './levels/level25'
import Level26 from './levels/level26'
import Level27 from './levels/level27'
import Level28 from './levels/level28'
import Level29 from './levels/level29'
import Level30 from './levels/level30'
import Level31 from './levels/level31'
import Level32 from './levels/level32'
import Level33 from './levels/level33'
import Level34 from './levels/level34'
import Level35 from './levels/level35'
import Level36 from './levels/level36'
import Level37 from './levels/level37'
import Level38 from './levels/level38'
import Level39 from './levels/level39'
import Level40 from './levels/level40'
import Level41 from './levels/level41'
import Level42 from './levels/level42'
import Level43 from './levels/level43'
import Level44 from './levels/level44'
import Level45 from './levels/level45'
import Level46 from './levels/level46'
import Level47 from './levels/level47'
import Level48 from './levels/level48'
import Level49 from './levels/level49'
import Level50 from './levels/level50'
import Level51 from './levels/level51'
import Level52 from './levels/level52'
import Level53 from './levels/level53'
import Level54 from './levels/level54'
import Level55 from './levels/level55'
import Level56 from './levels/level56'
import Level57 from './levels/level57'
import Level58 from './levels/level58'
import Level59 from './levels/level59'
import Level60 from './levels/level60'
import Level61 from './levels/level61'
import Level62 from './levels/level62'
import Level63 from './levels/level63'
import Level64 from './levels/level64'
import Level65 from './levels/level65'
import Level66 from './levels/level66'
import Level67 from './levels/level67'
import Level68 from './levels/level68'
import Level69 from './levels/level69'
import Level70 from './levels/level70'
import Level71 from './levels/level71'
import Level72 from './levels/level72'
import Level73 from './levels/level73'
import Level74 from './levels/level74'
import Level75 from './levels/level75'
import Level76 from './levels/level76'
import Level77 from './levels/level77'
import Level78 from './levels/level78'
import Level79 from './levels/level79'
import Level80 from './levels/level80'

export const GRID_ATTACK_GAME: GRID_ATTACK_GAME_TYPE = {
  gameNameId: 'gridattack',
  color: 'red',
  name: 'Grid Attack',
  description:
    'Demons kidnapped a powerful elf to steal his power. Go on an incredibly dangerous quest and save the world using a secret weapon – CSS Grid.',
  url: 'css-grid-attack',
  landingImageSrc: '/gamesAssets/gridattack/games-preview.png',
  tags: ['css'],

  seoTitle: 'Play Grid Attack – CSS Game to learn CSS Grid',
  seoDescription:
    'Learn css grid in a fun and interesting way by playing css coding game where you have to defeat elves from evil monsters!',
  seoPlayTitle: 'Play Grid Attack Game',
  seoPlayDescription:
    'Learn css grid in a fun and interesting way that works better than boring courses, by playing CSS game!',

  useGameStats() {
    const { user, loading, isLoaded } = useUser()

    let userGameStats: USER_GAME_STATS[] | [] = []

    const createGameStat = async (user: User) => {
      firebaseClient
        .firestore()
        .collection('users')
        .doc(user.uid)
        .update({
          games: [
            ...user.profile.games,
            { maxPlayedLevel: 1, gameNameId: 'gridattack', mode: 'easy' },
          ],
        })
    }

    if (user) {
      userGameStats = user.profile.games.filter(({ gameNameId }) => gameNameId === 'gridattack')

      if (userGameStats.length === 0) {
        createGameStat(user)
      }
    }

    return { userGameStats, loading, isLoaded }
  },
  async transferUnauthUserDataToAuthUser(user) {
    const gameStatsJSON = localStorage.getItem('gridattack')
    const gameStats = (gameStatsJSON && JSON.parse(gameStatsJSON)) || []

    return await Promise.allSettled(
      gameStats.map(({ maxPlayedLevel, mode }) => {
        return GRID_ATTACK_GAME.updateGameLevel({ level: maxPlayedLevel, mode, user })
      })
    )
  },
  async updateGameLevel({ level, mode, user }) {
    const gameStats = user.profile.games.find(
      (game) => game.gameNameId === 'gridattack' && mode === game.mode
    )

    let games: USER_GAME_STATS[] = []

    if (gameStats) {
      if (gameStats.maxPlayedLevel < level) {
        games = user.profile.games.map((game) => {
          if (game.gameNameId === 'gridattack' && mode === game.mode) {
            return {
              ...game,
              maxPlayedLevel: level,
            }
          }

          return game
        })

        await firebaseClient.firestore().collection('users').doc(user.uid).update({
          games,
        })

        mutate(
          '/api/user',
          () => ({
            ...user,
            profile: {
              ...user.profile,
              games,
            },
          }),
          false
        )
      }
    } else {
      const game = {
        maxPlayedLevel: level,
        mode,
        gameNameId: 'gridattack',
      }

      await firebaseClient
        .firestore()
        .collection('users')
        .doc(user.uid)
        .update({
          games: firebaseClient.firestore.FieldValue.arrayUnion(game),
        })

      mutate('/api/user')
    }

    return level
  },
  levels: [
    Level1,
    Level2,
    Level3,
    Level4,
    Level5,
    Level6,
    Level7,
    Level8,
    Level9,
    Level10,
    Level11,
    Level12,
    Level13,
    Level14,
    Level15,
    Level16,
    Level17,
    Level18,
    Level19,
    Level20,
    Level21,
    Level22,
    Level23,
    Level24,
    Level25,
    Level26,
    Level27,
    Level28,
    Level29,
    Level30,
    Level31,
    Level32,
    Level33,
    Level34,
    Level35,
    Level36,
    Level37,
    Level38,
    Level39,
    Level40,
    Level41,
    Level42,
    Level43,
    Level44,
    Level45,
    Level46,
    Level47,
    Level48,
    Level49,
    Level50,
    Level51,
    Level52,
    Level53,
    Level54,
    Level55,
    Level56,
    Level57,
    Level58,
    Level59,
    Level60,
    Level61,
    Level62,
    Level63,
    Level64,
    Level65,
    Level66,
    Level67,
    Level68,
    Level69,
    Level70,
    Level71,
    Level72,
    Level73,
    Level74,
    Level75,
    Level76,
    Level77,
    Level78,
    Level79,
    Level80,
  ],
}
