import React, { useCallback, useEffect, useState } from 'react'
import Head from 'next/head'
import { useToasts } from 'react-toast-notifications'
import cx from 'classnames'

import ModeModal, { Difficulty } from 'games/flexbox/components/ModeModal'
import { GAME_LEVEL, GAME_TYPE } from 'lib/constants'
import { USER_GAME_STATS } from 'lib/constants'
import { useIsMounted } from 'lib/hooks'
import { useUser } from 'lib/hooks'
import Layout from 'components/Layout'

import YouDidItModal from './components/YouDidItModal'
import Editor from './components/Editor/Editor'
import LevelCompletedModal from './components/LevelCompletedModal'

import s from './game.module.css'

export const GameContext = React.createContext()

const DEFAULT_MODE = 'easy'
const LOCAL_STORAGE_ITEM_NAME = 'flexbox'

export default function Game({ game }: { game: GAME_TYPE }) {
  // Load confetti library
  const [confettiLib, setConfettiLib] = useState()

  useEffect(() => {
    const module = require('canvas-confetti')

    setConfettiLib(module)
  }, [])

  // Toast library
  const { addToast } = useToasts()

  // Hooks
  const { user, isLoaded: isUserLoaded } = useUser()
  const isMounted = useIsMounted()

  // Game setup
  const { levels, useGameStats, updateGameLevel, color: gameColor } = game
  const { userGameStats } = useGameStats()

  const [answer, setAnswer] = useState('')
  const [mode, setMode] = useState<Difficulty>(DEFAULT_MODE)
  const [isGameLoaded, setGameLoaded] = useState(false)
  const [currentLevel, setCurrentLevel] = useState(1)
  const [maxPlayedLevel, setMaxPlayedLevel] = useState(1)

  const level: GAME_LEVEL = levels.find(({ level }) => currentLevel === level)

  const {
    descriptionBlock: DescriptionBlock,

    answers,

    board,

    completeLevelModalDescriptionBlock,
    checkUserAnswer,
  } = level

  // Modals setup
  const [isLevelPassedModalOpen, setLevelPassedModalOpen] = useState(false)
  const [isFinishModalOpen, setFinishModalOpen] = useState(false)
  const [isModeModalOpen, setModeModalOpen] = useState(true)

  const saveGameStats = useCallback(
    ({ level, mode }: { level: number; mode: string }) => {
      // Un auth
      const gameStatsJSON = localStorage.getItem(LOCAL_STORAGE_ITEM_NAME)
      const gameStats = (gameStatsJSON && JSON.parse(gameStatsJSON)) || []

      const gameModeMaxPlayedLevel =
        gameStats.find((gameStats: USER_GAME_STATS) => gameStats.mode === mode)?.maxPlayedLevel || 0

      if (level > gameModeMaxPlayedLevel && !user) {
        let newGameStats = []

        if (gameStats.length > 0) {
          if (gameStats.find((stats) => stats.mode === mode)) {
            newGameStats = gameStats?.map((stats) => {
              if (stats.mode === mode) {
                return {
                  ...stats,
                  maxPlayedLevel: level,
                }
              }

              return stats
            })
          } else {
            newGameStats = [
              ...gameStats,
              {
                mode,
                maxPlayedLevel: level,
                gameNameId: game.gameNameId,
              },
            ]
          }
        } else {
          newGameStats = [
            {
              mode,
              maxPlayedLevel: level,
              gameNameId: game.gameNameId,
            },
          ]
        }

        localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, JSON.stringify(newGameStats))

        setMaxPlayedLevel(level > gameModeMaxPlayedLevel ? level : gameModeMaxPlayedLevel)
      }

      // Auth
      if (user) {
        updateGameLevel({ level, mode, user })
        setMaxPlayedLevel(level > maxPlayedLevel ? level : maxPlayedLevel)
      }
    },
    [user, game.gameNameId, updateGameLevel, maxPlayedLevel]
  )

  // Initialization functions
  const loadAndSetAuthUserMaxLevel = useCallback(
    ({ mode: gameMode } = { mode }) => {
      const { maxPlayedLevel: level } = userGameStats?.find(({ mode }) => mode === gameMode) || {
        maxPlayedLevel: 1,
      }

      setCurrentLevel(level)
      setMaxPlayedLevel(level)

      return level
    },
    [mode, userGameStats]
  )

  const loadAndSetUnauthUserMaxLevel = useCallback(
    ({ mode: gameMode } = { mode }) => {
      const gameStatsJSON = localStorage.getItem(LOCAL_STORAGE_ITEM_NAME)
      const gameStats = (gameStatsJSON && JSON.parse(gameStatsJSON)) || []

      const gameModeMaxPlayedLevel =
        gameStats.find((gameStats: USER_GAME_STATS) => gameStats.mode === gameMode)
          ?.maxPlayedLevel || 0

      const level = gameModeMaxPlayedLevel || 1

      setCurrentLevel(level)
      setMaxPlayedLevel(level)

      return level
    },
    [mode]
  )

  // Initialization game
  useEffect(() => {
    if (!isGameLoaded && isUserLoaded) {
      if (user) {
        const level = loadAndSetAuthUserMaxLevel({ mode })

        saveGameStats({ level, mode })
        setGameLoaded(true)
      } else {
        const level = loadAndSetUnauthUserMaxLevel({ mode })

        saveGameStats({ level, mode })
        setGameLoaded(true)
      }
    }
  }, [
    user,
    isUserLoaded,
    isGameLoaded,
    mode,
    loadAndSetAuthUserMaxLevel,
    saveGameStats,
    loadAndSetUnauthUserMaxLevel,
  ])

  // Loading state while loading
  if (!isMounted() || !isGameLoaded) {
    return (
      <div className={cx(s.gameLayout, 'flex justify-center items-center flex-col')}>
        <div className="font-semibold text-5xl animate-spin mb-4">⚔️</div>
        <p className={cx(s.mainTextColor, 'font-medium text-5xl')}>Loading</p>
      </div>
    )
  }

  // Modals functions
  const handleCloseFinishModal = () => {
    setFinishModalOpen(false)
  }

  const handleEditorCodeChange = (value: string) => {
    setAnswer(value)
  }

  const handleCloseModalClick = () => {
    setLevelPassedModalOpen(false)
  }

  // Game functions
  const clearBoard = () => {
    setAnswer('')
    setLevelPassedModalOpen(false)

    document?.getElementById('gameField')?.removeAttribute('style')
    document?.getElementById('gameFieldBack')?.removeAttribute('style')
    document?.getElementById('marilyn')?.removeAttribute('style')
    document?.getElementById('haymitch')?.removeAttribute('style')
    document?.getElementById('arthur')?.removeAttribute('style')
  }

  const checkAnswer = () => {
    const isLastLevel = levels.length === currentLevel
    const isUserEnteredRightAnswer = checkUserAnswer({ answers, userAnswer: answer })

    if (!isUserEnteredRightAnswer) {
      addToast('Oops, wrong answer. Try again!', {
        appearance: 'error',
        autoDismiss: true,
      })
    }

    if (isUserEnteredRightAnswer) {
      confettiLib.default({
        particleCount: 100,
        spread: 70,
        origin: { y: 0.6 },
      })

      if (isLastLevel) {
        setFinishModalOpen(true)
      } else {
        saveGameStats({ level: currentLevel + 1, mode })

        setTimeout(() => {
          setLevelPassedModalOpen(true)
        }, 500)
      }
    }
  }

  const goNextLevel = () => {
    setLevelPassedModalOpen(false)
    clearBoard()

    const nextLevel = currentLevel + 1

    setCurrentLevel(nextLevel)
  }

  const handleLevelChoose = ({ item }) => {
    if (item.level !== currentLevel && item.level <= maxPlayedLevel) {
      setCurrentLevel(item.level)

      clearBoard()
    }
  }

  return (
    <GameContext.Provider value={{ mode, currentLevel, maxPlayedLevel, handleLevelChoose }}>
      <Head>
        <link
          rel="stylesheet"
          href="https://highlightjs.org/static/demo/styles/atom-one-dark.css"
        />
      </Head>

      <ModeModal
        color={gameColor}
        isOpen={isModeModalOpen}
        handleClose={() => setModeModalOpen(false)}
        handleChoose={({ mode }) => {
          setModeModalOpen(false)
          setMode(mode)

          let level = 1

          if (user) {
            level = loadAndSetAuthUserMaxLevel({ mode })
          } else {
            level = loadAndSetUnauthUserMaxLevel({ mode })
          }

          saveGameStats({ level, mode })
        }}
        defaultDifficulty={mode}
      />

      <LevelCompletedModal
        game={game}
        isOpen={isLevelPassedModalOpen}
        handleClose={handleCloseModalClick}
        completeLevelModalDescriptionBlock={completeLevelModalDescriptionBlock}
        goNextLevel={goNextLevel}
        currentLevel={currentLevel}
      />

      <YouDidItModal
        game={game}
        mode={mode}
        isOpen={isFinishModalOpen}
        handleClose={handleCloseFinishModal}
      />

      <Layout withoutFooter>
        <div className={s.gameLayout} id="flexboxGame">
          <div className={s.gameLayoutInner}>
            {/* DESCRIPTION */}
            <div className={cx(s.description, s.mainTextColor, 'p-4 lg:p-8')}>
              <DescriptionBlock mode={mode} />
            </div>

            <div className={s.editorWrapper}>
              {/* EDITOR */}
              <Editor
                game={game}
                level={level}
                answer={answer}
                handleEditorCodeChange={handleEditorCodeChange}
                checkAnswer={checkAnswer}
              />
            </div>

            <div className={s.codeField}>
              {/* FIELD */}
              {board({ answer })}
            </div>
          </div>
        </div>
      </Layout>
    </GameContext.Provider>
  )
}
