import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import useSWR from 'swr'
import { defaultSwrOptions } from 'lib/constants'
import { AuthContext } from 'components/AuthProvider'
import { firebaseClient } from 'lib/firebaseClient'
import { generateFantasyName } from './utils'

async function setUserEmptyProfile(user) {
  try {
    const userDoc = await firebaseClient.firestore().collection('users').doc(user?.uid).get()

    if (!userDoc.data()) {
      const profile = {
        uid: user?.uid,
        games: [],
        userName: generateFantasyName(),
      }

      firebaseClient.firestore().collection('users').doc(user?.uid).set(profile)
    }
  } catch (error) {
    console.error(error)
  }
}

export const useUser = () => {
  const { user, isLoaded: isLoadedFromContext, loading } = useContext(AuthContext)
  // console.log(user, isLoadedFromContext, loading)
  const { data, isValidating, mutate, error } = useSWR(
    user ? '/api/user' : null,
    async () => {
      const userDoc = await firebaseClient.firestore().collection('users').doc(user?.uid).get()

      const subscriptionDoc = await firebaseClient
        .firestore()
        .collection('customers')
        .doc(user?.uid)
        .collection('subscriptions')
        .where('status', 'in', ['trialing', 'active'])
        .get()

      const productsDoc = await firebaseClient
        .firestore()
        .collection('customers')
        .doc(user?.uid)
        .collection('payments')
        .where('status', 'in', ['succeeded'])
        .get()

      if (!subscriptionDoc.empty) {
        user.subscription = subscriptionDoc.docs[0].data()
      } else {
        user.subscription = null
      }

      if (!productsDoc.empty) {
        user.products = []

        productsDoc.forEach((doc) => {
          user.products.push(doc.data())
        })

        user.productsPaidIds = user.products.map(({ items }) => {
          return items[0].price.product
        })
      } else {
        user.products = null
        user.productsPaidIds = []
      }

      user.profile = userDoc.data()

      return user
    },
    {
      ...defaultSwrOptions,
      revalidateOnFocus: false,
    }
  )

  if (error) {
    console.error('/api/user error: ', error)
  }

  const [isLoaded, setLoaded] = useState(!!error || !!data)

  useEffect(() => {
    const isUserApiFetched = !!error || !!data

    if (isLoadedFromContext && !isValidating && !isLoaded && (isUserApiFetched || !user)) {
      setLoaded(true)
    }
  }, [isValidating, isLoaded, isLoadedFromContext, error, data, user])

  if (isLoaded && user && !user.profile) {
    // if some weird thing happened and user don't have a profile

    setUserEmptyProfile(user)

    const profile = {
      uid: user?.uid,
      games: [],
      userName: generateFantasyName(),
    }

    user.profile = profile
  }

  return { user: data, isLoaded, loading: loading || isValidating, mutate, error }
}

export function useIsMounted() {
  const isMountedRef = useRef(false)

  useEffect(() => {
    isMountedRef.current = true

    return () => {
      isMountedRef.current = false
    }
  }, [])

  return useCallback(() => isMountedRef.current, [])
}

export function useFirstRender() {
  const firstRender = useRef(true)

  useEffect(() => {
    firstRender.current = false
  }, [])

  return firstRender.current
}

export function useKeyPress(targetKey: string) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false)

  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true)
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false)
    }
  }

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return keyPressed
}
