import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import cx from 'classnames'

import s from '../../game.module.css'
import Head from 'next/head'

import LeftArrowSVG from 'public/images/svg/left-arrow.svg'
import { useCallback } from 'react'
import Inspector from '../Inspector'
import { useContext } from 'react'
import { GameContext } from 'games/gridattack/Game'

export const GameFieldSettingsContext = React.createContext({ isGridHidden: true })

export default function GameField({
  containerClassName = '',

  gameFieldBackStyle = '',
  gameFieldBackStyle2 = '',

  gameFieldStyle = '',
  className = '',

  gameField,
  gameFieldBack,
  gameFieldBackCharacters,

  canResize,

  inspector,
}: {
  containerClassName?: string

  gameFieldBackStyle?: string
  gameFieldBackStyle2?: string

  gameFieldStyle?: string
  className?: string
  canResize?: boolean

  gameField: () => JSX.Element
  gameFieldBack: () => JSX.Element
  gameFieldBackCharacters?: () => JSX.Element

  inspector: any[]
}) {
  // const [isHeroesHidden, setHeroesHidden] = useState(false)
  // const [isGridHidden, setGridHidden] = useState(true)
  const { setInspectorHidden, isInspectorHidden } = useContext(GameContext)
  const [isCanResizeVisible, setCanResizeVisible] = useState(true)

  const fieldRef = useRef(null)
  const fieldBackRef = useRef(null)
  const fieldBackCharactersRef = useRef(null)

  useLayoutEffect(() => {
    // if (gameFieldStyle) {
    //   fieldRef?.current?.setAttribute('style', gameFieldStyle)
    // }
    if (gameFieldBackStyle) {
      fieldBackRef?.current?.setAttribute('style', gameFieldBackStyle)
    }
    if (gameFieldBackStyle) {
      fieldBackCharactersRef?.current?.setAttribute('style', gameFieldBackStyle)
    }

    function outputsize() {
      document.getElementById('fieldContainer').style.width = document.getElementById(
        'fieldContainerResize'
      ).style.width
    }

    const resizeObserver = new ResizeObserver(outputsize)

    setTimeout(() => {
      resizeObserver.observe(document.getElementById('fieldContainerResize'))
    }, 0)

    return () => {
      resizeObserver.unobserve(document.getElementById('fieldContainerResize'))
      document?.getElementById('gameFieldBack')?.removeAttribute('style')
      document?.getElementById('gameFieldBackCharacters')?.removeAttribute('style')
    }
  }, [gameFieldBackStyle])

  return (
    // <GameFieldSettingsContext.Provider value={{ isGridHidden }}>
    <>
      <Head>
        <style>{gameFieldBackStyle2}</style>
      </Head>
      <Head>
        <style>{gameFieldStyle}</style>
      </Head>
      <div
        className={cx(
          'relative w-full flex flex-col justify-center items-center p-6 pt-8 lg:p-8 lg:pt-12'
        )}
      >
        <div
          id="fieldContainer"
          className={cx(
            `relative z-10`,
            s.fieldContainer,
            containerClassName
            // canResize && s.resizable
          )}
        >
          <div
            id="fieldContainerResize"
            className={cx(
              `absolute w-full h-full z-50`,
              s.fieldContainer,
              canResize && s.resizableAbsolute
            )}
            onPointerDownCapture={() => {
              if (canResize) {
                setCanResizeVisible(false)
              }
            }}
          ></div>
          <Inspector type="user" isHidden={isInspectorHidden}>
            <div
              id="field"
              className={cx(
                s.fieldGameField,
                `grid relative z-20 w-full h-full`,
                className
                // isHeroesHidden ? 'invisible' : ''
              )}
              ref={fieldRef}
            >
              {gameField()}
            </div>
          </Inspector>
          <Inspector type="field" isHidden={isInspectorHidden} settings={inspector}>
            <div
              id="gameFieldBack"
              className={`grid absolute z-30 top-0 left-0 w-full h-full`}
              ref={fieldBackRef}
            >
              {gameFieldBack()}
            </div>
          </Inspector>
          {gameFieldBackCharacters && (
            <div
              id="gameFieldBackCharacters"
              className={`grid absolute z-40 top-0 left-0 w-full h-full`}
              ref={fieldBackCharactersRef}
            >
              {gameFieldBackCharacters()}
            </div>
          )}
          <div className={s.fieldBackground} />
        </div>
        {canResize && isCanResizeVisible && (
          <div className={cx('relative', s.fieldContainerBoys)}>
            <div className={cx('absolute -bottom-12 right-0 flex items-end')}>
              <div className="text-white">Drag to resize</div>
              <LeftArrowSVG className="w-8" style={{ transform: 'scale(-1,1) rotate(90deg)' }} />
            </div>
          </div>
        )}
        <div
          className={cx(
            'flex items-center justify-start w-full mt-8 relative z-20',
            s.fieldContainerBoys
          )}
        >
          <input
            type="checkbox"
            id="hideHeroes"
            // defaultValue={isHeroesHidden ? 'checked' : ''}
            defaultChecked={isInspectorHidden}
            value={isInspectorHidden ? 'checked' : ''}
            onChange={(e) => {
              setInspectorHidden(e.target.checked)
            }}
          />
          <label htmlFor="hideHeroes" className={cx('ml-2 mr-4 text-white')}>
            Hide inspector
          </label>
          {/* <input
            type="checkbox"
            id="hideGrid"
            defaultChecked={isGridHidden}
            // defaultValue={isGridHidden ? 'checked' : ''}
            value={isGridHidden ? 'checked' : ''}
            onChange={(e) => {
              setGridHidden(e.target.checked)
            }}
          />
          <label htmlFor="hideGrid" className={cx('ml-2')}>
            Hide grid
          </label> */}
        </div>
      </div>
      {/* </GameFieldSettingsContext.Provider> */}
    </>
  )
}
